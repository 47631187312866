<template>
  <div class="pl-2">
    <CModal
      :title="modalTitle"
      :color="modalColor"
      :show.sync="isShowModal"
      :size="size"
      :closeOnBackdrop="closeOnBackdrop"
    >
      <template #header>
        <h6>{{ modalTitle }}</h6>
        <button aria-label="Close" class="close" @click="modalCallBack(false)">
          x
        </button>
      </template>
      <div v-if="isPreview">
        <div
          v-if="iframe.loaded && !iframe.isImg && !iframe.pdf.ispdf"
          class="embed-responsive embed-responsive-16by9 z-depth-1-half"
        >
          <iframe
            id="dociframe"
            class="embed-responsive-item bg-loader"
            :src="iframe.src"
            v-show="iframe.loaded"
          ></iframe>
        </div>
        <div
          v-else-if="iframe.isImg && !iframe.loaded && !iframe.pdf.ispdf"
          class="d-flex justify-content-center modalPdfContent"
        >
          <img
            :src="iframe.src"
            v-show="iframe.isImg"
            class="iframeclass"
            id="iframeimg"
          />
        </div>
        <div
          v-else-if="iframe.pdf.ispdf && !iframe.isImg && !iframe.loaded"
          class="wrapper modalPdfContent"
        >
          <pdf
            v-for="i in iframe.pdf.numPages"
            :key="i"
            :src="iframe.pdf.src"
            :page="i"
            style="overflow-y: auto"
          ></pdf>
        </div>
      </div>
      <template #footer-wrapper>
        <div class="border-top d-flex py-3 mx-3">
          <div class="flex-grow-1 d-flex">
            <CButton
              v-if="iframe.url"
              colour="primary"
              class="btn-link p-0 mx-2"
              download
              :href="`${iframe.url}`"
              v-c-tooltip="'Click to Download File'"
              ><i class="fas fa-download m-0"></i
            ></CButton>
          </div>

          <div class="d-flex">
            <div v-for="(button, index) in buttons" :key="index">
              <CButton
                :color="button == 'Cancel' ? 'secondary' : modalColor"
                @click="modalCallBack(button)"
                >{{ button }}</CButton
              >
            </div>
          </div>
        </div>
      </template>
    </CModal>
  </div>
</template>
<script>
import Modal from "@/components/reusable/Modal";
import pdf from "vue-pdf";
export default {
  extends: Modal,
  name: "PreviewModal",
  props: ["isPreview", "iframe", "size"],
  components: {
    pdf,
  },
};
</script>
<style lang="scss" scoped>
.iframeclass {
  width: 75%;
}
.modalPdfContent {
  height: 350px !important;
  overflow-y: scroll;
}
.wrapper {
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
@media all and (max-width: 480px) {
  .iframeclass {
    width: auto;
  }
}
</style>